import React, { useContext, useRef, useEffect } from 'react';
import { Layout, Theme } from '@allenai/varnish';

import Section from './Section';
import * as examples from './examples';

export const ImageTextToText = () => {
    const store = useContext(examples.ExampleStore);
    const io = examples.IOGroup.ImageTextToText;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current && store.scrollTarget === io) {
            ref.current.scrollIntoView();

            // Clear selection
            store.setScrollTarget(examples.IOGroup.Unknown);
        }
    }, [store.scrollTarget]);

    return (
        <div ref={ref}>
            <Layout bgcolor="N2">
                <Section.Content>
                    <examples.Header>
                        <examples.Heading>
                            <examples.HeadingInput>Image + Text</examples.HeadingInput> to{' '}
                            <examples.HeadingOutput>Text</examples.HeadingOutput>
                        </examples.Heading>
                        <examples.Description>
                            Unified-IO can understand complex inputs that include images, image
                            annotations such as bounding boxes, and text instructions and produces
                            the corresponding text output.{' '}
                            <strong>Choose a type of task to explore these capabilities.</strong>
                        </examples.Description>
                    </examples.Header>
                    {store.state === examples.StoreState.Ready ? (
                        <examples.Gallery io={io} bg={Theme.color.N2} />
                    ) : (
                        <examples.Loading />
                    )}
                </Section.Content>
            </Layout>
        </div>
    );
};

export default ImageTextToText;
