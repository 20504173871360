import styled from 'styled-components';

export const Text = styled.span`
    ${({ theme }) => `
        font-family: ${theme.typography.font.family.code};
        font-size: ${theme.typography.textStyles.big.fontSize};
        font-weight: bold;
        max-width: 256px;
        white-space: pre-line;
    `}
`;

export default Text;
