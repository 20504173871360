import React from 'react';
import { Color, Theme } from '@allenai/varnish';

interface Props {
    width?: number | string;
    height?: number | string;
    colors?: Color[];
}

export namespace Logo {
    export const Mark = (props: Props) => {
        const defaults = {
            width: 50,
            height: 50,
            colors: [Theme.color.A3, new Color('logoAlt', '#222367')],
        };
        const merged = Object.assign({}, defaults, props);
        return (
            <svg
                width={merged.width}
                height={merged.height}
                viewBox="0 0 127.8 127.8"
                xmlns="http://www.w3.org/2000/svg">
                <title>Unified-IO Icon</title>
                <path
                    fill={merged.colors[0].toString()}
                    d="M34,93.79l-.23-.22a40.82,40.82,0,0,1-12-29.73A43.35,43.35,0,0,1,64.29,21.31,40.85,40.85,0,0,1,94,33.29l.22.23,14-14C82.92-5.86,42.37-6.58,17.9,17.9s-23.76,65,1.6,90.37Z"
                />
                <path
                    fill={merged.colors[1].toString()}
                    d="M108.27,19.5l-14,14A40.84,40.84,0,0,1,106,63a43.34,43.34,0,0,1-43.14,42.52A41,41,0,0,1,34,93.79L19.5,108.27a65.79,65.79,0,0,0,45.73,19.52,61.23,61.23,0,0,0,62.56-62.56A65.75,65.75,0,0,0,108.27,19.5Z"
                />
                <path
                    fill={merged.colors[1].toString()}
                    d="M83.78,83.79A29.51,29.51,0,0,0,92.52,63.3a27.44,27.44,0,0,0-28-28A29.45,29.45,0,0,0,44,44Z"
                />
                <path
                    fill={merged.colors[0].toString()}
                    d="M44,44C32.65,55.38,32.33,73.54,43.29,84.5s29.13,10.65,40.49-.71Z"
                />
            </svg>
        );
    };
}

export default Logo;
