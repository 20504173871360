import React from 'react';
import styled from 'styled-components';
import { Content as C, belowOrEqualTo } from '@allenai/varnish';

import shareTwitterSrc from '../images/social-twitter-25px.svg';
import linkedInTwitterSrc from '../images/social-linkedin-32px.svg';

export namespace Intro {
    export const Content = styled(C)`
        ${({ theme }) => `
            padding-top: ${theme.spacing.xl3};

            &, h1, h2, h3, h4, h5, h6 {
                color: ${theme.color.N1};
            }
        `}
    `;

    export const Header = styled.div`
        ${({ theme }) => `
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: ${theme.spacing.xl};
            align-items: center;

            @media ${belowOrEqualTo(theme.breakpoints.md)} {
                display: block;
            }
        `}
    `;

    export const Title = styled.h1`
        ${({ theme }) => `
            display: grid;
            grid-template-columns: min-content 1fr;
            align-items: center;
            gap: ${theme.spacing.sm};
            white-space: nowrap;
            margin: 0;
            line-height: 1;
        `}
    `;

    export const Tagline = styled.h4<{ inverse?: boolean }>`
        ${({ theme, inverse }) => `
            border-left: 8px solid ${inverse ? theme.color.N10 : theme.color.N1};
            padding-left: ${theme.spacing.lg};
            margin: 0;
            font-weight: normal;

            @media ${belowOrEqualTo(theme.breakpoints.md)} {
                border: none;
                padding: 0;
                margin: ${theme.spacing.lg} 0 0;
            }
        `}
    `;

    export const Highlight = styled.em`
        ${({ theme }) => `
            font-weight: bold;
            color: ${theme.color.A3};
            font-style: normal;
        `}
    `;

    export const Share = () => {
        const text =
            'Unified-IO from @allen_ai is the first neural model that can perform a large, diverse set of AI tasks spanning from computer vision to natural language processing. Check it out:';
        const url = 'https://unified-io.allenai.org';

        const twitter = new URL('https://twitter.com/share');
        twitter.searchParams.set('text', text);
        twitter.searchParams.set('url', url);

        const linkedIn = new URL('https://www.linkedin.com/sharing/share-offsite/');
        linkedIn.searchParams.set('url', url);

        return (
            <SharePrompt>
                <span>Share to:</span>
                <a href={twitter.toString()} target="_blank" rel="noreferrer">
                    <img src={shareTwitterSrc} alt="Twitter" />
                </a>
                <a href={linkedIn.toString()} target="_blank" rel="noreferrer">
                    <img src={linkedInTwitterSrc} alt="LinkedIn" />
                </a>
            </SharePrompt>
        );
    };

    const SharePrompt = styled.span`
        ${({ theme }) => `
            display: grid;
            grid-template-columns: repeat(3, min-content);
            gap: ${theme.spacing.xs};
            white-space: nowrap;
            margin: ${theme.spacing.xs} 0 0;
        `}
    `;
}

export default Intro;
