import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { LoadingOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { belowOrEqualTo } from '@allenai/varnish';

import Card from './Card';
import * as api from '../api';
import * as ex from './examples';
import { Image } from './io';
import { scale, isTouchDevice } from '../util';

const bumperSize = 48;
const itemSize = 150;
const gapSize = 10;
const rows = 3;

export const TaskGrid = () => {
    const { taskGridExamples, state, tasksById, ioState, setIOState, setScrollTarget } = useContext(
        ex.ExampleStore
    );
    const ref = useRef<HTMLDivElement>(null);
    const setScrollInterval = useState<ReturnType<typeof window.setInterval> | undefined>()[1];
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const lenExamples = taskGridExamples.length;
    const numPerRow = Math.ceil(lenExamples / rows);
    const width = numPerRow * itemSize + (numPerRow - 1) * gapSize;

    useEffect(() => {
        if (ref.current) {
            const sl = (width - ref.current.clientWidth) / 2;
            setScrollLeft(sl);
            ref.current.scrollLeft = sl;
        }
    }, [state]);

    const stopScrolling = () => {
        setScrollInterval((si) => {
            if (si) {
                clearInterval(si);
            }
            return undefined;
        });
    };
    const maxScrollLeft = ref.current ? ref.current.scrollWidth - ref.current.clientWidth : 0;
    const startScrollingLeft = () => {
        const eventName = !isTouchDevice() ? 'mouseup' : 'touchend';
        const stopOnce = () => {
            document.body.removeEventListener(eventName, stopOnce);
            stopScrolling();
        };
        document.body.addEventListener(eventName, stopOnce);
        setScrollInterval(
            setInterval(() => {
                setScrollLeft((sl) => {
                    const nsl = Math.max(0, sl - 50);
                    if (ref.current) {
                        ref.current.scrollLeft = nsl;
                    }
                    return nsl;
                });
            }, 1000 / 60)
        );
    };
    const startScrollingRight = () => {
        const eventName = !isTouchDevice() ? 'mouseup' : 'touchend';
        const stopOnce = () => {
            document.body.removeEventListener(eventName, stopOnce);
            stopScrolling();
        };
        document.body.addEventListener(eventName, stopOnce);
        setScrollInterval(
            setInterval(() => {
                setScrollLeft((sl) => {
                    let nsl = sl;
                    if (ref.current) {
                        nsl = Math.min(maxScrollLeft, sl + 50);
                        ref.current.scrollLeft = nsl;
                    }
                    return nsl;
                });
            }, 1000 / 60)
        );
    };

    return (
        <Padding>
            <Container>
                {state !== ex.StoreState.Ready ? (
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 48, color: '#fff' }} spin />}
                    />
                ) : (
                    <>
                        <LeftBumper
                            onMouseDown={startScrollingLeft}
                            onTouchStart={startScrollingLeft}
                            style={scrollLeft === 0 ? { opacity: 0 } : {}}>
                            <CaretLeftOutlined style={{ fontSize: bumperSize, color: '#fff' }} />
                        </LeftBumper>
                        <ScrollRegion
                            ref={ref}
                            onScroll={() => {
                                if (ref.current) {
                                    setScrollLeft(ref.current.scrollLeft);
                                }
                            }}>
                            <Thumbs style={{ width }}>
                                {taskGridExamples.map((e) => {
                                    let src;
                                    let boxes: api.BoundingBox[] = [];
                                    if (e.output.image) {
                                        src = e.output.image;
                                        boxes = e.output.bboxes || [];
                                    } else if (e.input.image) {
                                        src = e.input.image;
                                        boxes = e.input.bboxes || [];
                                    }
                                    if (!src) {
                                        return null;
                                    }

                                    const fillBoundingBoxes = e.task === 'image_inpainting_coco';

                                    return src ? (
                                        <Card
                                            key={e.id}
                                            onSelected={() => {
                                                const io = ex.ioGroup(e);

                                                const updated = new Map(ioState);
                                                updated.set(io, {
                                                    task: tasksById.get(e.task),
                                                    example: e,
                                                });

                                                setIOState(updated);
                                                setScrollTarget(io);
                                            }}>
                                            <Card.Front>
                                                <Thumb>
                                                    <Image
                                                        src={src}
                                                        boxes={scale(150 / 256, boxes)}
                                                        maxWidth={150}
                                                        maxHeight={150}
                                                        fill={fillBoundingBoxes}
                                                    />
                                                </Thumb>
                                            </Card.Front>
                                            <Card.Back>
                                                {tasksById.get(e.task)?.name || e.task}
                                            </Card.Back>
                                        </Card>
                                    ) : null;
                                })}
                            </Thumbs>
                        </ScrollRegion>
                        <RightBumper
                            onMouseDown={startScrollingRight}
                            onTouchStart={startScrollingRight}
                            style={scrollLeft === maxScrollLeft ? { opacity: 0 } : {}}>
                            <CaretRightOutlined style={{ fontSize: bumperSize, color: '#fff' }} />
                        </RightBumper>
                    </>
                )}
            </Container>
        </Padding>
    );
};

export default TaskGrid;

const Padding = styled.div`
    ${({ theme }) => `
        padding: ${theme.spacing.xl2} ${theme.spacing.lg} ${theme.spacing.xl2};

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            padding: ${theme.spacing.lg} ${theme.spacing.md};
        }
    `}
`;

const centered = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    ${centered}
    height: ${itemSize * rows + gapSize * (rows - 1)}px;
    position: relative;
`;

const ScrollRegion = styled.div`
    ${centered}
    overflow-y: hidden;
    overflow-x: scroll;
    height: 100%;
    width: 100%;
    position: relative;
`;

const Thumbs = styled.div`
    ${centered}
    flex-wrap: wrap;
    gap: ${gapSize}px;
    height: 100%;
    position: absolute;
    left: 0;
`;

const Thumb = styled.div`
    width: ${itemSize}px;
    height: ${itemSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LeftBumper = styled.div`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        transition: opacity 0.2s ease-out;
        width: 60px;
        background: linear-gradient(90deg, #223367 26%, rgba(34, 51, 103, 0) 80%);
        z-index: 1;
        cursor: pointer;

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            > * {
                display: none;
            }
        }
    `}
`;

const RightBumper = styled(LeftBumper)`
    justify-content: flex-end;
    left: auto;
    right: 0;
    background: linear-gradient(270deg, #223367 26%, rgba(34, 51, 103, 0) 80%);
`;
