import * as api from './api';

export function normalizeQuestion(q: string): string {
    return q.replace(/\s+[?]$/, '?');
}

export function scale(factor: number, bboxes: api.BoundingBox[]): api.BoundingBox[] {
    return bboxes.map((box) => ({
        id: box.id,
        width: box.width * factor,
        height: box.height * factor,
        x: box.x * factor,
        y: box.y * factor,
    }));
}

export function isTouchDevice(): boolean {
    return window.matchMedia('(pointer: coarse)').matches;
}
