import styled from 'styled-components';

import Intro from '../Intro';
import UnifiedIOTheme from '../../UnifiedIOTheme';

export const Header = styled(Intro.Header)`
    ${({ theme }) => `
        margin: 0 0 ${theme.spacing.xl};
    `};
`;

export const Heading = styled.h2`
    margin: 0;
`;

export const Description = styled(Intro.Tagline).attrs({ inverse: true })`
    ${({ theme }) => `
        font-size: ${theme.typography.textStyles.big.fontSize};
    `};
`;

export const HeadingInput = styled.span`
    color: ${UnifiedIOTheme.Heading.Input.toString()};
`;

export const HeadingOutput = styled.span`
    color: ${UnifiedIOTheme.Heading.Output.toString()};
`;
