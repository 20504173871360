import React from 'react';
import { Layout, Theme } from '@allenai/varnish';

import Intro from './Intro';
import TaskGrid from './TaskGrid';
import Logo from './Logo';

export const Hero = () => {
    return (
        <Layout bgcolor="B10">
            <Intro.Content>
                <Intro.Header>
                    <div>
                        <Intro.Title>
                            <Logo.Mark
                                width="55"
                                height="55"
                                colors={[Theme.color.N1, Theme.color.A3]}
                            />
                            <span>Unified-IO</span>
                        </Intro.Title>
                        <Intro.Share />
                    </div>
                    <Intro.Tagline>
                        A new general-purpose model with{' '}
                        <Intro.Highlight>unprecedented breadth</Intro.Highlight>, Unified-IO can
                        perform a wide array of <Intro.Highlight>visual</Intro.Highlight> and{' '}
                        <Intro.Highlight>linguistic</Intro.Highlight> tasks.
                    </Intro.Tagline>
                </Intro.Header>
            </Intro.Content>
            <TaskGrid />
        </Layout>
    );
};

export default Hero;
