import styled from 'styled-components';
import { typography } from '@allenai/varnish';

type Size = keyof typeof typography.textStyles;

export const Copy = styled.div<{ size?: Size }>`
    ${({ theme, size }) => `
        font-size: ${theme.typography.textStyles[size || 'big'].fontSize};
        max-width: 80ch;
    `}
`;

export default Copy;
