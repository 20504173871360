import axios from 'axios';

export interface BoundingBox {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface IO {
    image?: string;
    bboxes?: BoundingBox[];
    text?: string;
    shortText?: string;
}

export interface Example {
    id: string;
    task: string;
    input: IO;
    output: IO;
}

function apiUrl(path: string): string {
    const examples = '2022-06-14';
    return `/static/${examples}${path}`;
}

export async function fetchExamples(): Promise<Example[]> {
    return axios.get<Example[]>(apiUrl(`/selected_examples.json`)).then((r) => r.data);
}

export interface TaskMeta {
    name: string;
    description: string;
    taskIds: string[];
}

export async function fetchTaskMeta(): Promise<TaskMeta[]> {
    return axios.get<TaskMeta[]>(apiUrl('/tasks.json')).then((r) => r.data);
}

export function imageURL(path: string): string {
    return apiUrl(`/${path}`);
}
