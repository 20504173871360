import React, { useEffect } from 'react';

import Hero from '../components/Hero';
import ImageTextToImage from '../components/ImageTextToImage';
import ImageTextToText from '../components/ImageTextToText';
import TextToImage from '../components/TextToImage';
import TextToText from '../components/TextToText';
import HowItWorks from '../components/HowItWorks';
import Credits from '../components/Credits';
import * as examples from '../components/examples';

export const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <examples.Fetch>
            <main>
                <Hero />
                <HowItWorks />
                <ImageTextToImage />
                <ImageTextToText />
                <TextToImage />
                <TextToText />
                <Credits />
            </main>
        </examples.Fetch>
    );
};
