import React from 'react';
import styled from 'styled-components';
import { Layout, belowOrEqualTo } from '@allenai/varnish';

import Section from '../components/Section';
import Copy from '../components/Copy';
import { HeadingOutput } from '../components/examples';
import { TeamList, Person } from './TeamList';
import priorSrc from '../images/prior-transparent.svg';

import aniSrc from '../images/ani.jpeg';
import roozbehSrc from '../images/roozbeh.jpeg';
import chrisSrc from '../images/chris.jpeg';
import rowanSrc from '../images/rowan.jpeg';
import jiasenSrc from '../images/jiasen.jpeg';

import samSrc from '../images/sam.jpeg';
import carissaSrc from '../images/carissa.jpeg';
import schmmdSrc from '../images/schmmd.jpeg';
import skjonsieSrc from '../images/skjonsie.jpeg';
import jonSrc from '../images/jon.jpeg';

const researchTeam: Person[] = [
    {
        name: 'Jiasen Lu',
        imgSrc: jiasenSrc,
    },
    {
        name: 'Christopher Clark',
        imgSrc: chrisSrc,
    },
    {
        name: 'Rowan Zellers',
        imgSrc: rowanSrc,
    },
    {
        name: 'Roozbeh Mottaghi',
        imgSrc: roozbehSrc,
    },
    {
        name: 'Ani Kembhavi',
        imgSrc: aniSrc,
    },
];

const demoTeam: Person[] = [
    {
        name: 'Sam Stuesser',
        imgSrc: samSrc,
    },
    {
        name: 'Sam Skjonsberg',
        imgSrc: skjonsieSrc,
    },
    {
        name: 'Jon Borchardt',
        imgSrc: jonSrc,
    },
    {
        name: 'Carissa Schoenick',
        imgSrc: carissaSrc,
    },
    {
        name: 'Michael Schmitz',
        imgSrc: schmmdSrc,
    },
];

export const Credits = () => (
    <Layout bgcolor="N1">
        <Section.Content>
            <Section.Heading>
                <HeadingOutput>Credits</HeadingOutput>
            </Section.Heading>
            <TeamCredits>
                <img src={priorSrc} width="200" />
                <Copy size="normal">
                    <p>
                        This demo page is built and maintained by{' '}
                        <a href="https://prior.allenai.org">PRIOR</a> and colleagues at the{' '}
                        <a href="https://allenai.org">Allen Insitute for AI</a>. Our team seeks to
                        advance computer vision to create AI systems that see, explore, learn, and
                        reason about the world.
                    </p>
                </Copy>
            </TeamCredits>
            <p>
                This research was made possible with cloud TPUs from{' '}
                <a href="https://sites.research.google/trc/about/">
                    Google’s TPU Research Cloud (TRC)
                </a>
                .
            </p>
            <p>
                Learn more about Unified-IO and the PRIOR team on the{' '}
                <a href="https://blog.allenai.org/tagged/computer-vision">AI2 Blog</a>. Follow{' '}
                <a href="https://www.twitter.com/allen_ai">@allenai_ai</a> on Twitter and subscribe
                to the{' '}
                <a href="https://semanticscholar.us10.list-manage.com/subscribe?u=fa6beaf5bd7a9ebe67d332ecb&id=ab68b6c3bb">
                    AI2 Newsletter
                </a>{' '}
                to stay current on news and research coming out of AI2.
            </p>

            <h3>
                <HeadingOutput>Research Team</HeadingOutput>
            </h3>
            <TeamList people={researchTeam} />

            <h3>
                <HeadingOutput>Demo Design + Development</HeadingOutput>
            </h3>
            <TeamList people={demoTeam} />
        </Section.Content>
    </Layout>
);

const TeamCredits = styled.div`
    ${({ theme }) => `
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: ${theme.spacing.lg};
        margin: 0 0 ${theme.spacing.lg};

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            display: block;

            img {
                margin: 0 0 ${theme.spacing.lg};
            }
        }
    `}
`;

export default Credits;
