import React from 'react';
import styled from 'styled-components';
import { belowOrEqualTo } from '@allenai/varnish';

import * as api from '../../api';
import { Image } from '../io';
import { scale } from '../../util';
import UnifiedIOTheme from '../../UnifiedIOTheme';

interface Props {
    examples: api.Example[];
    selected?: string;
    onClick: (example: api.Example) => void;
}

export const List = ({ examples, selected, onClick }: Props) => (
    <UL>
        {examples.map((ex) => {
            let src;
            let boxes: api.BoundingBox[] = [];
            if (ex.input.image) {
                src = ex.input.image;
                boxes = ex.input.bboxes || [];
            } else if (ex.output.image) {
                src = ex.output.image;
                boxes = ex.output.bboxes || [];
            }
            if (!src) {
                return null;
            }

            const fillBoundingBoxes = ex.task === 'image_inpainting_coco';

            return (
                <li key={ex.id}>
                    <Link key={ex.id} onClick={() => onClick(ex)}>
                        <Thumb>
                            <Image
                                src={src}
                                boxes={scale(100 / 256, boxes)}
                                maxWidth={100}
                                maxHeight={100}
                                fill={fillBoundingBoxes}
                                border={
                                    selected === ex.id
                                        ? UnifiedIOTheme.Example.Input.toString()
                                        : 'transparent'
                                }
                            />
                        </Thumb>
                    </Link>
                </li>
            );
        })}
    </UL>
);

const Thumb = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UL = styled.ul`
    ${({ theme }) => `
        display: flex;
        gap: ${theme.spacing.lg};
        margin: ${theme.spacing.xl} 0 0;
        flex-wrap: wrap;
        justify-content: center;
        list-style-type: none;

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            gap: ${theme.spacing.sm};
        }
    `}
`;

const Link = styled.a`
    ${({ theme }) => `
        background: ${theme.color.N3};
        transition: transform 0.2s;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    `}
`;

export default List;
