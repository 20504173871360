import styled from 'styled-components';

import { Content as C } from '@allenai/varnish';

export namespace Section {
    export const Heading = styled.h2`
        ${({ theme }) => `
            margin: 0 0 ${theme.spacing.xl};
        `}
    `;

    export const Content = styled(C)<{ inverse?: boolean }>`
        ${({ theme, inverse }) => `
            padding-top: ${theme.spacing.xl3};
            padding-bottom: ${theme.spacing.xl3};
            ${
                inverse
                    ? `
                &, h1, h2, h3, h4, h5, h6 {
                    color: ${theme.color.N1};
                }

                a, a:hover, a:visited {
                    color: ${theme.color.A3};
                }
            `
                    : ''
            }
        `}
    `;
}

export default Section;
