import React, { useContext, useRef, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Select from 'antd/es/select';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Color, Theme, belowOrEqualTo } from '@allenai/varnish';

import * as io from '../io';
import * as api from '../../api';
import * as util from '../../util';
import List from './List';
import { ExampleStore, IOGroup } from './Store';
import Loading from './Loading';
import Logo from '../Logo';
import UnifiedIOTheme from '../../UnifiedIOTheme';

export const Gallery = ({ io: ioGroup, bg }: { io: IOGroup; bg?: Color }) => {
    const store = useContext(ExampleStore);

    const examplesByTask = store.examplesByIO.get(ioGroup) || new Map();
    const taskIds = Array.from(examplesByTask.keys());

    return (
        <io.TaskTabs taskIds={taskIds} io={ioGroup}>
            {({ task }) => {
                const examples = [];
                for (const id of task?.taskIds || []) {
                    const te = examplesByTask.get(id) || [];
                    examples.push(...te);
                }
                return task && examples.length !== 0 ? (
                    <TaskExamples task={task} examples={examples} io={ioGroup} bg={bg} />
                ) : (
                    <Loading />
                );
            }}
        </io.TaskTabs>
    );
};

function isElementInViewport(e: HTMLElement): boolean {
    const bounds = e.getBoundingClientRect();
    if (bounds.top < 0) {
        return false;
    }
    if (bounds.left < 0) {
        return false;
    }
    if (bounds.right > window.innerWidth) {
        return false;
    }
    if (bounds.bottom > window.innerHeight) {
        return false;
    }
    return true;
}

function isSmallScreen(): boolean {
    return window.matchMedia(`(max-width: ${Theme.breakpoints.md.toString()})`).matches;
}

const TaskExamples = ({
    task,
    examples,
    io: ioGroup,
    bg,
}: {
    task: api.TaskMeta;
    examples: api.Example[];
    io: IOGroup;
    bg?: Color;
}) => {
    const { ioState, setIOState } = useContext(ExampleStore);
    const isFirstRender = ioState.get(ioGroup) === undefined;
    const example = ioState.get(ioGroup)?.example || examples[0];
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isSmallScreen()) {
            return;
        }
        if (isFirstRender) {
            return;
        }
        if (!ref.current) {
            return;
        }
        if (isElementInViewport(ref.current)) {
            return;
        }
        ref.current.scrollIntoView();
    }, [example, ref.current, isFirstRender]);

    const fillBoundingBoxes = task.taskIds.some((tid) => tid === 'image_inpainting_coco');

    return (
        <div ref={ref}>
            <TaskMeta>
                <h3>{task.name}</h3>
                {task.description}
            </TaskMeta>
            <io.Columns data-example-id={example.id}>
                <io.Input>
                    {example.input.image ? (
                        <io.Image
                            src={example.input.image}
                            boxes={example.input.bboxes || []}
                            fill={fillBoundingBoxes}
                            border={Theme.color.N1.toString()}
                        />
                    ) : null}
                    {example.input.text ? (
                        <io.Text>{util.normalizeQuestion(example.input.text)}</io.Text>
                    ) : null}
                </io.Input>
                <Connection>
                    <VerticalLine />
                    <InputIndicator>
                        <HorizontalLine />
                    </InputIndicator>
                    <MarkContainer>
                        <OccludeLine bg={bg}>
                            <Logo.Mark width={48} height={48} />
                        </OccludeLine>
                    </MarkContainer>
                    <OutputIndicator>
                        <ArrowRightOutlined
                            style={{
                                fontSize: 48,
                                color: Theme.color.N10.toString(),
                            }}
                        />
                    </OutputIndicator>
                </Connection>
                <io.Output>
                    {example.output.image ? (
                        <io.Image
                            src={example.output.image}
                            boxes={example.output.bboxes || []}
                            fill={fillBoundingBoxes}
                            border={Theme.color.N1.toString()}
                        />
                    ) : null}
                    {example.output.text ? <io.Text>{example.output.text}</io.Text> : null}
                </io.Output>
            </io.Columns>
            {ioGroup === IOGroup.TextToText || ioGroup === IOGroup.TextToImage ? (
                <Row>
                    <label>
                        <strong>Try another example:</strong>
                    </label>
                    <Select
                        value={example.id}
                        onSelect={(id: string) => {
                            const ex = examples.find((e) => e.id === id) || example;
                            const updated = new Map(ioState);
                            updated.set(ioGroup, { example: ex, task });
                            setIOState(updated);
                        }}>
                        {examples.map((ex) => (
                            <Select.Option key={ex.id} value={ex.id}>
                                {ex.input.shortText || ex.input.text}
                            </Select.Option>
                        ))}
                    </Select>
                    <SelectDropdownMenuCSS />
                </Row>
            ) : (
                <List
                    examples={examples}
                    selected={example?.id}
                    onClick={(ex) => {
                        const updated = new Map(ioState);
                        updated.set(ioGroup, { example: ex, task });
                        setIOState(updated);
                    }}
                />
            )}
        </div>
    );
};

const TaskMeta = styled.div`
    ${({ theme }) => `
        text-align: center;
        font-size: ${theme.typography.textStyles.big.fontSize};
        margin: ${theme.spacing.xl} 0;

        h3 {
            margin 0;
        }
    `}
`;

const Connection = styled.div`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    align-items: center;
    width: 48px;
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    z-index: 1;

    ${({ theme }) => `
        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            height: 48px;
            width: initial;
            grid-template-rows: none;
            grid-template-columns: min-content 1fr min-content;
            align-items: normal;
            justify-content: center;
            margin-left: 0;
            margin-right: 0;
            margin-top: -10px;
            margin-bottom: -10px;
        }
    `}
`;

const InputIndicator = styled.div`
    height: 48px;
    display: flex;
    align-items: center;

    ${({ theme }) => `
        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            height: auto;
        }
    `}
`;

const OutputIndicator = styled.div`
    > * {
        position: relative;
        right: -18px;

        ${({ theme }) => `
            @media ${belowOrEqualTo(theme.breakpoints.md)} {
                transform: rotate(90deg);
                right: initial;
                bottom: -18px;
            }
        `}
    }
`;

const MarkContainer = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
`;

const OccludeLine = styled.div<{ bg?: Color }>`
    ${({ theme, bg }) => `
        background: ${bg || theme.color.N1};
        position: relative;
        z-index: 2;
        display: grid;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 68px;

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            width: 68px;
            height: 28px;

            svg {
                margin-top: -10px;
            }
        }
    `}
`;

const HorizontalLine = styled.div`
    ${({ theme }) => `
        height: 4px;
        width: 38px;
        position: relative;
        left: -12px;
        background: ${theme.color.N10};

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            transform: rotate(90deg);
            left: initial;
            top: -17px;
            left: 4px;
        }
    `}
`;

const VerticalLine = styled.div`
    ${({ theme }) => `
        position: absolute;
        width: 4px;
        left: 50%;
        margin-left: -2px;
        top: 24px;
        bottom: 24px;
        background: ${theme.color.N10};

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            top: 50%;
            left: 24px;
            bottom: initial;
            right: 24px;
            margin-left: 0;
            margin-top: -2px;
            width: 68px;
            height: 28px;
            height: 4px;
            width: initial;
        }
    `}
`;

const Row = styled.div`
    ${({ theme }) => `
        display: grid;
        grid-template-columns: min-content minmax(0, 1fr);
        gap: ${theme.spacing.md};
        align-items: center;
        margin: ${theme.spacing.xl} 0 0;

        label {
            white-space: nowrap;
        }

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            display: block;

            .ant-select {
                margin: ${theme.spacing.xs2} 0 0;
                max-width: 100%;
            }
        }

        .ant-select,
        .ant-select:not(.ant-select-customize-input) {
            .ant-select-selector {
                background: ${UnifiedIOTheme.Example.Input};
                color: #fff;
                border: none;
                border-radius: 0;
            }

            .ant-select-arrow {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .ant-select-single.ant-select-open .ant-select-selection-item {
            color: #fff;
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: none;
            box-shadow: none;
        }
    `}
`;

const SelectDropdownMenuCSS = createGlobalStyle`
    .ant-select-dropdown {
        background: ${UnifiedIOTheme.Example.Input.toString()};
        border: none;
        border-radius: 0;
    }

    .ant-select-item-option {
        color: #fff;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: ${UnifiedIOTheme.Example.Output.toString()};
        color: #fff;
    }
`;

export default Gallery;
