import React from 'react';
import styled from 'styled-components';
import { Color } from '@allenai/varnish';

import * as api from '../../api';

export type IOType = 'input' | 'output';

interface Props {
    src: string;
    boxes: api.BoundingBox[];
    fill?: boolean;
    maxWidth?: string | number;
    maxHeight?: string | number;
    border?: string;
}

export const Image = ({ src, boxes, fill, maxWidth, maxHeight, border }: Props) => {
    const style: React.CSSProperties = {};
    if (maxWidth !== undefined && maxWidth !== null) {
        style.maxWidth = maxWidth;
    }
    if (maxHeight !== undefined && maxHeight !== null) {
        style.maxHeight = maxHeight;
    }

    return (
        <Origin border={border}>
            <img src={api.imageURL(src)} style={style} />
            {boxes.map((b, i) => (
                <Box
                    key={`${b.id}-${i}`}
                    fillBox={fill}
                    style={{ left: b.x, top: b.y, width: b.width, height: b.height }}
                />
            ))}
        </Origin>
    );
};

const Origin = styled.div<{ border?: string }>`
    position: relative;

    ${({ border }) =>
        border
            ? `
        border: 4px solid ${border};
    `
            : ''};
`;

function bg(c: Color, alpha: number = 0.1): string {
    const parts = [c.rgb.r, c.rgb.g, c.rgb.b];
    return `rgba(${parts.join(',')}, ${alpha})`;
}

const highContrastColor = new Color('aruba', '#00ff01');

const Box = styled.div<{ highContrast?: boolean; fillBox?: boolean }>`
    ${({ theme, highContrast, fillBox }) => `
        position: absolute;
        border: 2px solid ${highContrast ? highContrastColor : theme.color.A3};
        background: ${bg(highContrast ? highContrastColor : theme.color.A3, fillBox ? 1 : 0.1)};
    `}
`;

export default Image;
