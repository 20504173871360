import React from 'react';
import ReactDOM from 'react-dom';
import { VarnishApp } from '@allenai/varnish';

import '@allenai/varnish/theme.css';

import { App } from './App';

ReactDOM.render(
    <VarnishApp>
        <App />
    </VarnishApp>,
    document.getElementById('root')
);
