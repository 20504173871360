import styled from 'styled-components';

import UnifiedIOTheme from '../../UnifiedIOTheme';

export namespace Heading {
    export const Input = styled.span`
        color: ${UnifiedIOTheme.Heading.Input.toString()};
    `;

    export const Output = styled.span`
        color: ${UnifiedIOTheme.Heading.Output.toString()};
    `;
}
