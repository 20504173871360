import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Theme } from '@allenai/varnish';

export const Loading = () => (
    <Container>
        <Spin
            indicator={
                <LoadingOutlined style={{ fontSize: 48, color: Theme.color.M6.toString() }} spin />
            }
        />
    </Container>
);

const Container = styled.div`
    ${({ theme }) => `
        display: grid;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacing.xl2};
    `}
`;

export default Loading;
