import React from 'react';
import styled from 'styled-components';

import * as api from '../../api';

interface Props {
    examples: api.Example[];
    onClick: (example: api.Example) => void;
}

export const ExampleList = ({ examples, onClick }: Props) => (
    <List>
        {examples.map((ex) => (
            <ListItem key={ex.id} onClick={() => onClick(ex)}>
                {ex.input.image || ex.output.image ? (
                    <img
                        src={api.imageURL(ex.input.image || ex.output.image || '')}
                        width="100"
                        height="100"
                    />
                ) : null}
            </ListItem>
        ))}
    </List>
);

const List = styled.div`
    ${({ theme }) => `
        display: flex;
        gap: ${theme.spacing.lg};
        margin: ${theme.spacing.xl} 0 0;
        flex-wrap: wrap;
        justify-content: center;
    `}
`;

const ListItem = styled.a`
    ${({ theme }) => `
        display: block;
        cursor: pointer;
        background: ${theme.color.N3};
        transition: transform 0.2s;

        &:hover {
            transform: scale(1.05);
        }
    `}
`;
