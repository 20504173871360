import styled, { css } from 'styled-components';
import { belowOrEqualTo } from '@allenai/varnish';

import UnifiedIOTheme from '../../UnifiedIOTheme';

const ioStyles = css`
    ${({ theme }) => `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${theme.spacing.lg};
        padding: ${theme.spacing.xl};
        color: ${UnifiedIOTheme.Example.Text.toString()};

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            min-height: 350px;
        }
    `}
`;

export const Input = styled.div`
    ${ioStyles};

    background: ${UnifiedIOTheme.Example.Input.toString()};
`;

export const Output = styled.div`
    ${ioStyles};

    background: ${UnifiedIOTheme.Example.Output.toString()};
`;
