import React, { useState, useEffect, createContext } from 'react';

import * as api from '../../api';

export enum StoreState {
    Loading,
    Ready,
    Error,
}

export enum IOGroup {
    ImageTextToImage,
    ImageTextToText,
    TextToImage,
    TextToText,
    Unknown,
}

export type ExamplesByTask = Map<string, api.Example[]>;
export type ExamplesByIO = Map<IOGroup, ExamplesByTask>;

export function ioGroup(ex: api.Example): IOGroup {
    const hasInputImage = !!ex.input.image;
    const hasInputText = !!ex.input.text;

    const hasOutputImage = !!ex.output.image;
    const hasOutputText = !!ex.output.text;

    if (hasInputImage && hasInputText && hasOutputImage && !hasOutputText) {
        return IOGroup.ImageTextToImage;
    }

    if (hasInputImage && hasInputText && hasOutputText && !hasOutputImage) {
        return IOGroup.ImageTextToText;
    }

    if (hasInputText && !hasInputImage && hasOutputImage && !hasOutputText) {
        return IOGroup.TextToImage;
    }

    if (hasInputText && !hasInputImage && hasOutputText && !hasOutputImage) {
        return IOGroup.TextToText;
    }

    return IOGroup.Unknown;
}

interface IOGroupState {
    task?: api.TaskMeta;
    example?: api.Example;
}

function examplesByIO(examples: api.Example[]): ExamplesByIO {
    const byIO = new Map();
    for (const ex of examples) {
        const io = ioGroup(ex);
        if (io === IOGroup.Unknown) {
            console.warn('discarding example with unknown io group:', ex);
            continue;
        }

        const byTask = byIO.get(io) || new Map();
        const examples = byTask.get(ex.task) || [];
        byTask.set(ex.task, examples.concat([ex]));

        byIO.set(io, byTask);
    }
    return byIO;
}

function tasksById(tasks: api.TaskMeta[]): Map<string, api.TaskMeta> {
    const byId = new Map();
    for (const task of tasks) {
        for (const taskId of task.taskIds) {
            if (byId.has(taskId)) {
                throw new Error(`duplicate task id: ${taskId}`);
            }
            byId.set(taskId, task);
        }
    }
    return byId;
}

interface Store {
    state: StoreState;
    examples: api.Example[];
    taskGridExamples: api.Example[];
    examplesByIO: ExamplesByIO;
    tasksById: Map<string, api.TaskMeta>;
    ioState: Map<IOGroup, IOGroupState>;
    setIOState: (s: Map<IOGroup, IOGroupState>) => void;
    scrollTarget: IOGroup;
    setScrollTarget: (io: IOGroup) => void;
}

export const ExampleStore = createContext<Store>({
    state: StoreState.Loading,
    examples: [],
    taskGridExamples: [],
    examplesByIO: new Map(),
    tasksById: new Map(),
    ioState: new Map(),
    setIOState: () => {},
    scrollTarget: IOGroup.Unknown,
    setScrollTarget: () => {},
});

function shuffle<T>(arr: T[]): T[] {
    const shuffled = [];
    while (arr.length > 0) {
        const idx = Math.floor(Math.random() * arr.length);
        shuffled.push(arr.splice(idx, 1)[0]);
    }
    return shuffled;
}

export const Fetch = ({ children }: { children: React.ReactNode }) => {
    const [examples, setExamples] = useState<api.Example[]>([]);
    const [taskGridExamples, setTaskGridExamples] = useState<api.Example[]>([]);
    const [tasks, setTaskMeta] = useState<api.TaskMeta[]>([]);
    const [state, setState] = useState<StoreState>(StoreState.Loading);
    const [ioState, setIOState] = useState<Map<IOGroup, IOGroupState>>(new Map());
    const [scrollTarget, setScrollTarget] = useState<IOGroup>(IOGroup.Unknown);

    useEffect(() => {
        setState(StoreState.Loading);
        const allRequests = Promise.all([
            api.fetchExamples().then((examples) => {
                setExamples(examples);
                const withImage = examples.filter((ex) => ex.input.image || ex.output.image);
                setTaskGridExamples(shuffle(withImage).slice(0, 128));
            }),
            api.fetchTaskMeta().then(setTaskMeta),
        ]);
        allRequests
            .then(() => setState(StoreState.Ready))
            .catch((err: any) => {
                console.error('loading:', err);
                setState(StoreState.Error);
            });
    }, []);

    const store = {
        state,
        examples,
        taskGridExamples,
        ioState,
        setIOState,
        scrollTarget,
        setScrollTarget,
        examplesByIO: examplesByIO(examples),
        tasksById: tasksById(tasks),
    };
    return <ExampleStore.Provider value={store}>{children}</ExampleStore.Provider>;
};

export default Fetch;
