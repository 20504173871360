import React from 'react';
import styled from 'styled-components';
import { Layout, Theme, belowOrEqualTo } from '@allenai/varnish';

import Section from './Section';
import Copy from './Copy';
import LinkIcon from './LinkIcon';
import Diagram from './Diagram';

const paperURL = 'https://arxiv.org/abs/2206.08916';
const codeURL = 'https://github.com/allenai/unified-io-inference';

export const HowItWorks = () => {
    return (
        <Layout bgcolor="B10">
            <Section.Content inverse>
                <Section.Heading>How Does Unified-IO Work?</Section.Heading>
                <Columns>
                    <Copy>
                        <p>
                            Unified-IO is the first neural model to perform a large and diverse set
                            of AI tasks spanning <strong>classical computer vision</strong>,{' '}
                            <strong>image synthesis</strong>, <strong>vision-and-language</strong>,
                            and <strong>natural language processing (NLP)</strong>. Unified-IO
                            achieves this broad unification by homogenizing every task's input and
                            output into a sequence of tokens drawn from a discrete and finite
                            vocabulary. Dense inputs such as images, masks, and depth maps are
                            converted to sequences using a universal compressor, and sparse
                            structured inputs such as bounding boxes and human joint locations are
                            transcribed into language, which is naturally sequential.
                        </p>
                        <p>
                            This approach of unifying input and output data enables us to train a
                            single sequence-to-sequence Unified IO model to perform tasks across
                            more than 80 diverse computer vision and NLP benchmarks.
                        </p>
                        <p>
                            Unified-IO is a significant milestone in the pursuit of a single unified
                            general purpose system capable of parsing and producing visual,
                            linguistic, and other structured data.{' '}
                            <a href="https://blog.allenai.org/introducing-ai2s-unified-io-9c0ec7fe1e43">
                                Read more about Unified-IO&nbsp;&nbsp;
                                <LinkIcon color={Theme.color.A3.toString()} />
                            </a>
                            .
                        </p>
                    </Copy>
                    <div>
                        <Diagram />
                        {paperURL ? (
                            <LinkBox>
                                <Icon>
                                    <PaperIcon />
                                </Icon>
                                <a href={paperURL}>
                                    Unified-IO: Sequential Modeling for Generally Applicable Vision
                                    Models
                                </a>
                                <Icon>
                                    <GitHubIcon />
                                </Icon>
                                <a href={codeURL}>View Unified-IO Source Code on GitHub</a>
                            </LinkBox>
                        ) : null}
                    </div>
                </Columns>
            </Section.Content>
        </Layout>
    );
};

const Icon = styled.div`
    display: flex;
    justify-content: center;
`;

const PaperIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="35"
        viewBox="0 0 28 35"
        role="img"
        aria-hidden="true">
        <title>Document icon</title>
        <path
            d="M27.3,6.3l-6.8-5.8C20.2,0.2,19.7,0,19.1,0h-17C1.6,0,1,0.2,0.6,0.5C0.2,0.8,0,1.3,0,1.8v31.4c0,0.5,0.2,0.9,0.6,1.3 C1,34.8,1.5,35,2.1,35h23.8c0.5,0,1.1-0.2,1.5-0.5s0.6-0.8,0.6-1.3V7.6C27.9,7,27.7,6.6,27.3,6.3z M6,9.5c0-0.7,0.6-1.3,1.3-1.3h9 c0.7,0,1.3,0.6,1.3,1.3v0c0,0.7-0.6,1.3-1.3,1.3h-9C6.6,10.8,6,10.2,6,9.5L6,9.5z M22,25.5c0,0.7-0.6,1.3-1.3,1.3H7.3 c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h13.4C21.4,24.2,22,24.8,22,25.5L22,25.5z M22,20.2c0,0.7-0.6,1.3-1.3,1.3H7.3 c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h13.4C21.4,18.8,22,19.4,22,20.2L22,20.2z M22,14.8c0,0.7-0.6,1.3-1.3,1.3H7.3 c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h13.4C21.4,13.5,22,14.1,22,14.8L22,14.8z"
            fill={Theme.color.N9.toString()}></path>
    </svg>
);

const GitHubIcon = () => (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4810_40505)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.1677 0C9.12426 0 0.168945 8.95421 0.168945 20.0012C0.168945 28.8366 5.899 36.3329 13.8472 38.9779C14.8472 39.1613 15.2122 38.5438 15.2122 38.0138C15.2122 37.539 15.1949 36.2813 15.1852 34.6122C9.62187 35.8209 8.44801 31.9313 8.44801 31.9313C7.53859 29.6208 6.22744 29.0057 6.22744 29.0057C4.41155 27.7648 6.36483 27.7899 6.36483 27.7899C8.37189 27.9314 9.42827 29.851 9.42827 29.851C11.2124 32.9073 14.1092 32.0248 15.2488 31.5128C15.4305 30.2203 15.9472 29.339 16.5186 28.8391C12.0774 28.3344 7.40857 26.6185 7.40857 18.9545C7.40857 16.7711 8.18783 14.9858 9.46719 13.5877C9.26154 13.0817 8.57521 11.0483 9.66327 8.29476C9.66327 8.29476 11.3425 7.75699 15.1631 10.345C16.7584 9.90143 18.4693 9.67957 20.1701 9.67184C21.8685 9.67957 23.5795 9.90143 25.1771 10.345C28.9954 7.75699 30.6716 8.29476 30.6716 8.29476C31.7627 11.0483 31.0762 13.0817 30.8706 13.5877C32.1524 14.9858 32.9264 16.7711 32.9264 18.9545C32.9264 26.6377 28.2503 28.3283 23.7948 28.8235C24.5129 29.4409 25.1526 30.6616 25.1526 32.5272C25.1526 35.201 25.128 37.3581 25.128 38.0138C25.128 38.5486 25.4882 39.1709 26.5032 38.9755C34.4437 36.3256 40.1689 28.8343 40.1689 20.0012C40.1689 8.95421 31.2135 0 20.1677 0Z"
                fill="#47515C"
            />
        </g>
        <defs>
            <clipPath id="clip0_4810_40505">
                <rect width="40" height="40" fill="white" transform="translate(0.168945)" />
            </clipPath>
        </defs>
    </svg>
);

const LinkBox = styled.div`
    ${({ theme }) => `
        background: ${theme.color.N3};
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: ${theme.spacing.md};
        margin: ${theme.spacing.xl} 0 0;
        padding: ${theme.spacing.lg};
        align-items: center;
        font-size: ${theme.typography.textStyles.big.fontSize};

        a, a:hover, a:visited {
            color: ${theme.color.B6};
        }
    `};
`;

const Columns = styled.div`
    ${({ theme }) => `
        display: grid;
        grid-template-columns: minmax(40ch, 1fr) minmax(0, 520px);
        gap: ${theme.spacing.lg};

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            display: block;
        }
    `}
`;

export default HowItWorks;
