import React from 'react';
import { Header, Layout, Footer } from '@allenai/varnish';

import { Home } from './pages';

export const App = () => {
    return (
        <Layout bgcolor="white">
            <Header.AI2Banner />
            <Home />
            <Footer variant="dark" />
        </Layout>
    );
};
