import React, { useContext } from 'react';
import styled from 'styled-components';

import UnifiedIOTheme from '../../UnifiedIOTheme';
import * as api from '../../api';
import * as examples from '../examples';

interface RenderProps {
    task?: api.TaskMeta;
}

type RenderTaskTab = (props: RenderProps) => React.ReactNode;

interface Props {
    taskIds: string[];
    children: RenderTaskTab;
    io: examples.IOGroup;
}

export const TaskTabs = ({ taskIds, children, io }: Props) => {
    const store = useContext(examples.ExampleStore);

    const uniqueTasks: Set<api.TaskMeta> = new Set();
    for (const id of taskIds) {
        const task = store.tasksById.get(id);
        if (!task) {
            continue;
        }
        uniqueTasks.add(task);
    }
    const tasks = Array.from(uniqueTasks).sort((a, b) => {
        const an = a.name.toLocaleLowerCase();
        const bn = b.name.toLocaleLowerCase();
        return an.localeCompare(bn);
    });

    let selectedTask = tasks.find((t) => {
        const state = store.ioState.get(io);
        return state && t === state.task;
    });
    if (!selectedTask) {
        selectedTask = tasks[0];
    }

    return (
        <>
            {tasks.length > 1 ? (
                <Tabs>
                    {tasks.map((task) => (
                        <Tab
                            key={task.taskIds.join('-')}
                            active={selectedTask === task}
                            onClick={() => {
                                const updated = new Map(store.ioState);
                                updated.set(io, { task });
                                store.setIOState(updated);
                            }}>
                            {task.name}
                        </Tab>
                    ))}
                </Tabs>
            ) : null}
            {children({ task: selectedTask })}
        </>
    );
};

const Tabs = styled.div`
    ${({ theme }) => `
        display: flex;
        gap: ${theme.spacing.md};
        margin: ${theme.spacing.xl} 0;
        flex-wrap: wrap;
    `}
`;

const Tab = styled.a<{ active?: boolean }>`
    ${({ theme, active }) => `
        font-weight: bold;
        background: ${
            active ? UnifiedIOTheme.TaskTab.Active.Background : UnifiedIOTheme.TaskTab.Background
        };
        padding: ${theme.spacing.sm} ${theme.spacing.lg};
        line-height: 1;
        font-size: ${theme.typography.textStyles.micro.fontSize};
        border-radius: ${
            2 * theme.spacing.sm.getValue() + parseFloat(theme.typography.textStyles.micro.fontSize)
        }px;
        color: ${active ? UnifiedIOTheme.TaskTab.Active.Text : UnifiedIOTheme.TaskTab.Text};
        transition: none;

        &:hover {
            color: ${
                active ? UnifiedIOTheme.TaskTab.Active.Text : UnifiedIOTheme.TaskTab.Hover.Text
            };
            text-decoration: none;
        }

        @media (hover: hover) {
            &:hover {
                background: ${
                    active
                        ? UnifiedIOTheme.TaskTab.Active.Background
                        : UnifiedIOTheme.TaskTab.Hover.Background
                };

            }
        }
    `}
`;

export default TaskTabs;
