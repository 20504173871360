import React from 'react';

export const LinkIcon = (props: { className?: string; color?: string }) => (
    <svg
        className={props.className}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00024 3.5L10.5003 3.5L10.5004 7.99998" stroke={props.color} />
        <path d="M3 5.5H0.5V13.5H8.5V11" stroke={props.color} />
        <path d="M3 11L10.5 3.5" stroke={props.color} />
    </svg>
);

export default LinkIcon;
