import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { isTouchDevice } from '../util';

const Card = ({ children, onSelected }: { children: React.ReactNode; onSelected: () => void }) => {
    const [flipped, setFlipped] = useState<boolean>(false);
    const [timers, setTimers] = useState<ReturnType<typeof setTimeout>[]>([]);

    const clearTimers = () => {
        timers.forEach((t) => clearTimeout(t));
        setTimers([]);
    };

    return (
        <Outer
            onMouseEnter={() => {
                if (!isTouchDevice()) {
                    clearTimers();
                    setFlipped(true);
                }
            }}
            onMouseLeave={() => {
                if (!isTouchDevice()) {
                    clearTimers();
                    const timer = setTimeout(() => setFlipped(false), 200);
                    setTimers(timers.concat([timer]));
                }
            }}
            onClick={() => {
                // On non-touch devices fire selection on click
                if (!isTouchDevice()) {
                    onSelected();
                    return;
                }
                // On touch devices fire selected if the card is flipped.
                if (flipped) {
                    onSelected();
                    return;
                }

                // Otherwise flip the card. Flip it back after a timeout.
                setFlipped(true);
                setTimeout(() => {
                    setFlipped(false);
                }, 2000);
            }}>
            <Inner style={{ transform: flipped ? 'rotateY(180deg)' : '' }}>{children}</Inner>
        </Outer>
    );
};

const sideStyles = css`
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visiblity: hidden;
`;

Card.Front = styled.div`
    ${sideStyles}

    ${({ theme }) => `
        background: ${theme.color.B9};
    `}
`;

Card.Back = styled.div`
    ${({ theme }) => `
        ${sideStyles}
        background: ${theme.color.A3};
        color: ${theme.color.B10};
        line-height: 1.38;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotateY(180deg);
        text-align: center;
        padding: ${theme.spacing.xs};
    `}
`;

const Outer = styled.div`
    background: transparent;
    width: 150px;
    height: 150px;
    perspective: 1000px;
    cursor: pointer;
`;

const Inner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    transform-style: preserve-3d;
`;

export default Card;
