import { Theme, Color } from '@allenai/varnish';

type UnifiedIOThemeDef = {
    Heading: {
        Input: Color;
        Output: Color;
    };
    Example: {
        Input: Color;
        Output: Color;
        Text: Color;
    };
    TaskTab: {
        Active: {
            Background: Color;
            Text: Color;
        };
        Hover: {
            Background: Color;
            Text: Color;
        };
        Background: Color;
        Text: Color;
    };
};

export const UnifiedIOTheme: UnifiedIOThemeDef = {
    Heading: {
        Input: Theme.color.M7,
        Output: Theme.color.B6,
    },
    TaskTab: {
        Active: {
            Background: Theme.color.M7,
            Text: Theme.color.N1,
        },
        Hover: {
            Background: Theme.color.N10,
            Text: Theme.color.N1,
        },
        Background: Theme.color.N9,
        Text: Theme.color.N1,
    },
    Example: {
        Input: Theme.color.M7,
        Output: Theme.color.B4,
        Text: Theme.color.N1,
    },
};

export default UnifiedIOTheme;
