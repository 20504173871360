import styled from 'styled-components';
import { belowOrEqualTo } from '@allenai/varnish';

export const Columns = styled.div`
    ${({ theme }) => `
        display: grid;
        grid-template-columns: 1fr min-content 1fr;
        min-height: 520px;

        @media ${belowOrEqualTo(theme.breakpoints.md)} {
            display: block;
            min-height: auto;
        }
    `}
`;

export default Columns;
