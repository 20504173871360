import React from 'react';
import styled from 'styled-components';
import { textStyles } from '@allenai/varnish';

const { Big } = textStyles;

export interface Person {
    imgSrc: string;
    name: string;
}

interface Props {
    people: Person[];
}

export const TeamList = ({ people }: Props) => {
    return (
        <List>
            {people.map((person, i) => {
                return (
                    <li key={`${person.name}-${i}`}>
                        <img width="160" height="160" src={person.imgSrc}></img>
                        <PersonInfo>
                            <Big>
                                <strong>{person.name}</strong>
                            </Big>
                        </PersonInfo>
                    </li>
                );
            })}
        </List>
    );
};

const PersonInfo = styled.div`
    ${({ theme }) => `
        margin-top: ${theme.spacing.sm};
    `};
`;

const List = styled.ul`
    ${({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing.md};
        list-style-type: none;

        &, li {
            margin: 0;
            padding: 0;
        }
    `}
`;

export default TeamList;
